/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import JSXParser from 'react-jsx-parser';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import MeasurementUtils from 'appdir/lib/analytics';
import Button from 'appdir/components/common-ui/Button';
import EventsLink from 'appdir/components/general/EventsLink';

import cn from 'classnames';

/**
 * -----------------------------------------------------------------------------
 * React Component: TicketsModule
 * -----------------------------------------------------------------------------
 */

export default class TicketsModule extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.props,
			selectedDates: [],
			selectedTime: 'all',
			maxToShow: 3,
			displayIncrement: 3,
		};

		this.loadURL = false;
		this.selectDate = this.selectDate.bind(this);
		this.openModal = this.openModal.bind(this);
		this.loadMorePressed = this.loadMorePressed.bind(this);

		this.today = moment();
		//this.today = moment("08-26-2024").hour(20).minutes(0).seconds(0);
		logger.log('[TicketsModule] constructor - today:%o', this.today.format('MM-DD-YYYY hh:mm a'));
	}

	componentDidMount() {
		if (this.state.hasOwnProperty('mount')) {
			this.props.mount(this);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		this.getURLDayInfo();
	}

	/**
	 * @function selectTime
	 * Handler for selecting time range from dropdown
	 * @param {*} e
	 */
	selectTime = e => {
		this.setState(
			{
				...this.state,
				selectedTime: e.target.value,
			},
			() => {
				MeasurementUtils.dispatchMeasurementCall('selectTime', {
					filter: this.state.selectedTime,
				});
			}
		);
	};

	/**
	 * @function selectDate
	 * handler for date selection from calendar
	 * @param {*} selectedDate
	 */
	selectDate(selectedDate) {
		logger.log('[TicketsModule] selectDate - selectedDate:%o', selectedDate);
		let remove = false;
		let newDate = this.state.selectedDates;

		//if fanweek get all days for selectedDate
		if (selectedDate === 'fanweek') {
			let fwDates = [];
			let otherDate = [];

			this.props.tickets.dates.filter(f => {
				if (f.tournDay === 'fanweek') {
					fwDates.push(f.date);
				}
			});
			selectedDate = fwDates.slice();
			// logger.log("[TicketsModule] selectDate - selectedDate:%o, fwDates: %o", selectedDate,fwDates);

			selectedDate.map(item => {
				if (this.state.selectedDates.indexOf(item) !== -1) {
					remove = true;
				}
			});

			MeasurementUtils.dispatchMeasurementCall('SelectDate', {
				filter: { selection: selectedDate },
			});

			if (remove === true) {
				//remove multiple dates for fanweek
				selectedDate.map(sd => {
					newDate = newDate.filter(d => d !== sd);
				});
			} else {
				//add multiple dates
				newDate = newDate.concat(selectedDate);
			}
		} else {
			if (this.state.selectedDates.indexOf(selectedDate) !== -1) {
				remove = true;
			}

			MeasurementUtils.dispatchMeasurementCall('SelectDate', {
				filter: { selection: selectedDate },
			});

			if (remove === true) {
				newDate = newDate.filter(d => d !== selectedDate);
			} else {
				newDate.push(selectedDate);
			}
		}

		// logger.log("[TicketsModule] selectDate - newDate:%o", newDate);
		this.setState({
			...this.state,
			selectedDates: newDate,
		});
	}

	/**
	 * -----------------------------------------------------------------------------
	 * @function genDates
	 * creates a specified row of dates
	 * -----------------------------------------------------------------------------
	 */
	genDates(dates = [], calType) {
		let dateJSX = [];

		// why is this in config? usta requested that fanweek display dates be hardcoded, regardless of actual dates of fanweek events
		let fwStartDate = moment(this?.props?.fanweekInfo?.startDate);
		let fwEndDate = moment(this?.props?.fanweekInfo?.endDate);

		// just separating the list between specific calendar type and regular tournament dates
		// (was thinking there might be somethng other than fanweek, then i got in the weeds.  sooo ... fanweek hardcoded everywehre. )
		// no judgement pls.

		let displayList = calType
			? dates.filter((data, i) => {
					return data.tournDay == calType;
			  })
			: dates.filter((data, i) => {
					return parseInt(data.tournDay, 10) > 0;
			  });

		//logger.log("[TicketsModule] genDates - displayList:%o", displayList);

		if (!isEmpty(dates)) {
			let fwMonth = [];
			let fwDay = [];
			let fwWeekday = [];

			displayList?.map((item, index) => {
				//logger.log("[TicketsModule] genDates - dates item: %o, index:%o, dateJSX:%o", item, index, dateJSX);

				if (item.tournDay == calType) {
					//logger.log("[TicketsModule] genDates - displayList.length:%o, index:%o", displayList.length, index);

					let itemDate = this.getDateTime(item);

					let fwPast = index !== 0 && itemDate.isBefore(this.today); // is fanweek in the past?

					// let's try to get a generated fanweek date range to display.
					// these blocks get the first fanweek item and the last fanweek item
					fwMonth[index] =
						index == 0 ? (
							//<span key={`ma${index}`}>{item.month}</span>
							<span key={`ma${index}`}>{fwStartDate?.format('MMM')}</span>
						) : index == displayList.length - 1 ? (
							<>
								<span key={`md${index}`}>&nbsp;</span>
								{/* <span key={`m${index}`}>{item.month}</span> */}
								<span key={`m${index}`}>{fwEndDate?.format('MMM')}</span>
							</>
						) : (
							''
						);
					fwDay[index] =
						index == 0 ? (
							// <span key={`daya${index}`}>{item.dateDay}</span>
							<span key={`daya${index}`}>{fwStartDate?.format('D')}</span>
						) : index == displayList.length - 1 ? (
							<>
								<span key={`dd${index}`}>-</span>
								{/* <span key={`day${index}`}>{item.dateDay}</span> */}
								<span key={`day${index}`}>{fwEndDate.format('D')}</span>
							</>
						) : (
							''
						);

					fwWeekday[index] =
						index == 0 ? (
							// <span key={`dayw${index}`}>{itemDate?.format('ddd')}</span>
							<span key={`dayw${index}`}>{fwStartDate?.format('ddd')}</span>
						) : index == displayList.length - 1 ? (
							<>
								<span key={`dd${index}`}></span>
								{/* <span key={`day${index}`}>{itemDate?.format('ddd')}</span> */}
								<span key={`day${index}`}>{fwEndDate?.format('ddd')}</span>
							</>
						) : (
							''
						);

					let isSelected = this.state.selectedDates.indexOf(item.date) > -1 ? true : false;

					const dateClassNames = () => {
						return cn({
							date: true,
							select: isSelected,
							fanweek: true,
							disabled: fwPast,
						});
					};

					// only push a calendar item if we are at the end of the list
					if (index == displayList.length - 1) {
						// the key for this should be uniqe as there's only going to be one of them
						// i don't know why there's a warning.

						dateJSX.push(
							<div
								className={dateClassNames()}
								onClick={!fwPast ? () => this.selectDate(item.tournDay) : null}
								id={item.tournDay}
								key={item.date + index}>
								<time className={`date-as-calendar`}>
									<span className="month">{fwMonth}</span>
									<span className="fw-day">{fwDay}</span>
									<span className="fw-week">{fwWeekday}</span>
								</time>
							</div>
						);
					}
				} else if (!calType) {
					//logger.log("[TicketsModule] genDates - item.tournDay:%o, type:%o, eval:%o", item.tournDay, calType, item.tournDay !== calType);

					let itemDate = this.getDateTime(item);
					// let itemDate = moment(item.date)
					// 	.hour(23)
					// 	.minutes(59)
					// 	.seconds(59);
					let inPast = false; // is the date in the past??

					let isSelected = this.state.selectedDates.indexOf(item.date) > -1 ? true : false;

					if (itemDate.isBefore(this.today)) {
						inPast = true;
					}

					const dateClassNames = () => {
						return cn({
							date: true,
							disabled: inPast,
							select: isSelected,
						});
					};

					dateJSX.push(
						<div
							className={dateClassNames()}
							id={item.date}
							onClick={!inPast ? () => this.selectDate(item.date) : null}
							key={item.date + item.tournDay}>
							<time dateTime={item.date} className="date-as-calendar">
								<span className="month">{item.month}</span>
								<span className="day">{itemDate.format('D')}</span>
								<span className="weekday">{item.day}</span>
							</time>
						</div>
					);
				}
			});
			//logger.log('[TicketsModule] genDates - dateJsx:%o', dateJSX);

			return dateJSX;
		} else {
			return null;
		}
	}

	/**
	 * -----------------------------------------------------------------------------
	 * @function setTimeIcon
	 * determines which icon to display after time (i don't think this is used anymore?)
	 * @var singleSessionDate : sets the cut of date to not show the icons
	 * @var eveningHours : sets the time of day to display the moon icon
	 * -----------------------------------------------------------------------------
	 */
	setTimeIcon(date, time) {
		let singleSessionDate = moment('2019-09-05', 'YYYY-MM-DD');
		let eventDate = moment(date, 'YYYY-MM-DD');
		let eveningHours = moment('5:00pm', 'h:mma');
		let eventTime = moment(time, 'h:mma');
		let meridiem = '';

		if (moment(eventDate).isSameOrAfter(singleSessionDate)) {
			meridiem = '';
		} else {
			if (moment(eventTime).isSameOrAfter(eveningHours)) {
				meridiem = 'pm';
			} else {
				meridiem = 'am';
			}
		}

		return meridiem;
	}

	/**
	 * @function genSelectTime
	 * generates the jsx for the select time dropdown
	 * @returns
	 */
	genSelectTime() {
		let menulist = {
			options: [
				{ name: 'Day and Evening', value: 'all' },
				{ name: 'Day', value: 'day' },
				{ name: 'Evening', value: 'evening' },
			],
		};
		return (
			<div className="selectTime">
				{/* <div className="text">What time of day would you like?</div> */}

				<div className="tickets-select-menu">
					{/* <SelectMenu
						name="tickets_time_select"
						selected={this.state?.selectedTime}
						klass="dark blueBg select-menu"
						attributes={menulist}
						onSelected={this.selectTime}
						onOpen={this.onOpen}
					/> */}
					<select onChange={this.selectTime} className="tickets-dropdown-select">
						{menulist.options.map(option => (
							<option key={option.name} value={option.value}>
								{option.name}
							</option>
						))}
					</select>
				</div>
			</div>
		);
	}

	/**
	 * @function getDateTime
	 * @param {*} data
	 * @returns This function attempts to return a moment object for the date and time supplied in the data.  If there's no time field, i'm setting to the
	 * end of the day as this is for filtering based on the entire day.  this is used so i can use moment functions for before/after comparisons
	 */
	getDateTime(data) {
		let time = data?.time
			? data.time
					?.split('/')?.[0]
					?.trim()
					?.toLowerCase() // let's just take the first time entered in time field
			: '11:59 pm';
		let hour = parseInt(time.split(':')?.[0]?.trim(), 10); // get the hour
		let min = parseInt(
			time
				.split(':')?.[1]
				?.trim()
				?.split(' ')?.[0],
			10
		);
		let am_pm = time?.toLowerCase().includes('am') ? 'am' : 'pm'; // find out if it is am or pm

		// logger.log(
		// 	'[TicketsModule] getDateTime - title:%o, time:%o, hour:%o, min:%o,  am_pm:%o',
		// 	data.title,
		// 	time,
		// 	hour,
		// 	min,
		// 	am_pm
		// );
		let timeObj = moment(`${data?.date?.replace(/-/g, '/')} ${hour}:${min} ${am_pm}`);
		//logger.log('[TicketsModule] getDateTime - today:%o, timeObj:%o', this.today.format('MM-DD-YYYY hh:mm a'), timeObj.format('MM-DD-YYYY hh:mm a'));
		return timeObj;
	}

	/**
	 * @function matchSelectedTime
	 * @param {*} data
	 * @returns This function is for the day/evening/all dropdown to filter out events that fit the criteria.
	 */
	matchSelectedTime(data) {
		let selectedTime = this.state.selectedTime;
		let match = false;
		//logger.log('[TicketsModule] matchSelectedTime - data:%o', data);

		if (selectedTime == 'all') {
			match = true;
		} else {
			let time = data?.time
				?.split('/')?.[0]
				?.trim()
				?.toLowerCase(); // let's just take the first time entered in time field
			let hour = parseInt(time.split(':')?.[0]?.trim(), 10); // get the hour
			let am_pm = time?.toLowerCase().includes('am') ? 'am' : 'pm'; // find out if it is am or pm

			// logger.log(
			// 	'[TicketsModule] matchSelectedTime - selectedTime:%o, title:%o, time:%o, hour:%o, am_pm:%o',
			// 	selectedTime,
			// 	data.title,
			// 	time,
			// 	hour,
			// 	am_pm
			// );

			// if the event title has "day" or "evening", then assume it is that .. regardless of the time
			if (data.title.toLowerCase().indexOf(`(${selectedTime})`) !== -1) {
				// logger.log('[TicketsModule] matchSelectedTime - 1');
				match = true;
			}

			// after 7pm is evening
			if (selectedTime == 'evening' && hour >= 7 && am_pm == 'pm' && hour !== 12) {
				// logger.log('[TicketsModule] matchSelectedTime - 2');
				match = true;
			}

			// anything anything before 7pm is considered day
			if (selectedTime == 'day' && (hour < 7 || (hour > 7 && am_pm == 'am') || (hour == 12 && am_pm == 'pm'))) {
				// logger.log('[TicketsModule] matchSelectedTime - 3');
				match = true;
			}
		}

		// logger.log('[TicketsModule] matchSelectedTime - match:%o', match);

		return match;
	}

	// /** send metrics call for apps on the Draws Bracket view deep link tap on Power Index to Bracket view
	//  *  and deep link to the bracket view
	//  */
	openModal(showModal, stadium, sessionIndex, tournDay) {
		logger.log(
			'[TicketsModule] openModal - stadium:%o, session:%o, showModal:%o',
			stadium,
			sessionIndex,
			showModal
		);

		this.props.loadOverlayModal('TICKETS_INFO', { stadium, sessionIndex });
		MeasurementUtils.dispatchMeasurementCall('openModal', {
			session: stadium?.session?.[sessionIndex],
			modalType: 'TICKETS_INFO',
		});

		//doMeasurement(values.powerIndexTitle, 'Draws Bracket View', measureArgs, contextData);
	}

	/**
	 * @function genSessionCards
	 * generates the jsx for each session card for a date filtering if it meets the date/time criteria
	 * @param {*} tickets
	 * @param {*} tournDay
	 * @param {*} pindex
	 * @returns
	 */
	genSessionCards(tickets, tournDay, pindex) {
		return tickets.map((stadium, index) => {
			return stadium.session.map((session, index2) => {
				//let itemDate = moment(session.date).hour(23).minutes(59).seconds(59);

				// using this, because session cards shouldn't display if past the event time
				let itemDate = this.getDateTime(session);

				return this.matchSelectedTime(session) && itemDate.isSameOrAfter(this.today) ? (
					<div
						key={`session${index}${index2}`}
						className={`ticket-session-card${tournDay == 'fanweek' ? ' ' + tournDay : ''}`}>
						{/* <div className="image-wrapper"> */}
						<img
							className="card-image"
							src={
								session?.images?.[0]?.url ? session?.images?.[0]?.url : '/assets/images/misc/trans.gif'
							}
							alt={session?.images?.[0]?.caption ? session?.images?.[0]?.caption : stadium.title}
							style={{ width: '100%', aspectRatio: '16/9' }}
						/>
						{/* </div> */}
						<div className="card-info-wrapper">
							<div className="session-event">{session.event}</div>
							<div className="session-title">{session.title}</div>
							<div className="session-date">
								{moment(`${session.date.replace(/-/g, '/')}`).format('dddd, MMMM DD')} - {session.time}
							</div>
							<div className="session-number">{session?.number ? `Session ${session?.number}` : ''}</div>
							<div className="card-btn-wrapper">
								{session?.link && session?.link !== '' && (
									<EventsLink to={session?.link} style={`btn-tix-link`}>
										<Button className="session-btn tix">{session?.buttonText}</Button>
									</EventsLink>
								)}
								{session?.more_info_link ? (
									<EventsLink to={session?.more_info_link} style={`btn-tix-link`}>
										<Button className="session-btn tix">{session?.more_info_text}</Button>
									</EventsLink>
								) : (
									<Button
										className={`session-btn info`}
										onClick={e => {
											this.openModal(true, stadium, index2);
										}}>
										More Info
									</Button>
								)}
							</div>
						</div>
					</div>
				) : null;
				//<div>There are no events available for the selected date and time period.</div>
			});
		});
	}

	/**
	 * -----------------------------------------------------------------------------
	 * @function genSelectDatesInfo
	 * populates info box for selected date
	 * @var row : determines which row the info should be displayed under
	 * -----------------------------------------------------------------------------
	 */
	genSelectDatesInfo(row) {
		let selectedDates = this.state.selectedDates;

		logger.log('[TicketsModule] genSelectDatesInfo - selectedDates:%o', selectedDates);

		let lastFwDate = this?.props?.tickets?.dates?.findLast((ticket, index) => {
			return ticket.tournDay == 'fanweek';
		});
		let fwDate = this.getDateTime(lastFwDate);

		logger.log('[TicketsModule] genSelectDatesInfo - lastFwDate:%o', lastFwDate);

		let displayIndex = fwDate?.isSameOrAfter(this.today) ? 0 : -1;
		let disableLoadMore = false;

		return (
			<>
				{this?.props?.tickets?.dates?.map((s_date, index) => {
					//logger.log('[TicketsModule] genSelectDatesInfo - s_date:%o, index:%o, length:%o', s_date, index, this?.props?.tickets?.dates?.length);
					let itemDate = this.getDateTime(s_date);

					// let itemDate = moment(s_date.date)
					// 	.hour(23)
					// 	.minutes(59)
					// 	.seconds(59);

					//let fwDate = this.getDateTime(lastFwDate);

					// let fwDate = moment(lastFwDate.date)
					// 	.hour(23)
					// 	.minutes(59)
					// 	.seconds(59);

					// used for load more.  load more only valid when no dates are selected
					if (s_date?.tournDay !== 'fanweek' && itemDate.isSameOrAfter(this.today)) {
						// increment display index only for tourn days since fanweek days are grouped
						displayIndex++;
					}

					if (
						this?.props?.tickets?.dates?.length > 0 &&
						index == this?.props?.tickets?.dates?.length - 1 &&
						displayIndex < this.state.maxToShow
					) {
						// used to disable loadmore button
						disableLoadMore = true;
					}
					// -------------------------------------------------------------------
					//logger.log('[TicketsModule] genSelectDatesInfo - displayIndex:%o, maxToShow:%o, disableLoadMore:%o', displayIndex, this.state.maxToShow, disableLoadMore);
					return (selectedDates.length == 0 && displayIndex < this.state.maxToShow) ||
						selectedDates.includes(s_date.date) ? (
						<Fragment key={'sdi' + index + s_date.date}>
							{s_date.tournDay == 'fanweek' && fwDate?.isSameOrAfter(this.today) ? (
								<>
									{index == 0 && (
										<div
											className={`stadium-ticket-wrapper ${
												s_date.tournDay == 'fanweek' ? ' fanweek' : ''
											}`}>
											<div className="stadium_ticket">
												<div className="titleHeader date-group">
													<span className="header-month">FAN WEEK</span>
												</div>

												<div className="session-group">
													{fwDate.isSameOrAfter(this.today) ? (
														<>
															{this?.props?.tickets?.dates?.map((s_date2, index2) => {
																return s_date2.tournDay == 'fanweek' ? (
																	<Fragment key={`sc${index2}${index}`}>
																		{this.genSessionCards(
																			s_date2.tickets,
																			s_date.tournDay,
																			index2
																		)}
																	</Fragment>
																) : null;
															})}
														</>
													) : (
														<div className="message">
															There are no events available for the selected date and time
															period.
														</div>
													)}
												</div>
											</div>
										</div>
									)}
								</>
							) : null}
							{s_date.tournDay !== 'fanweek' && itemDate.isSameOrAfter(this.today) ? (
								<div className={`stadium-ticket-wrapper`}>
									<div className="stadium_ticket">
										<div className="titleHeader date-group">
											<span className="header-month">{itemDate.format('MMMM')}</span>
											<span className="header-day">{itemDate.format('D')}</span>
											<span className="header-week">{itemDate.format('dddd')}</span>
										</div>
										{!isEmpty(s_date?.theme) && (
											<div
												className={`theme-card ${
													s_date?.theme?.borderTop
														? `theme-${s_date?.theme?.borderTop?.toLowerCase()}`
														: ''
												}`}>
												<div className="title">{s_date?.theme?.title}</div>
												<div className="description">
													<JSXParser jsx={s_date?.theme?.description} />
												</div>
												{s_date?.theme?.link && (
													<div className="link">
														<EventsLink to={s_date?.theme?.link}>More Info</EventsLink>
													</div>
												)}
											</div>
										)}
										<div className="session-group">
											{itemDate?.isSameOrAfter(this.today) ? (
												this.genSessionCards(s_date.tickets, s_date.tournDay, index)
											) : (
												<div className="message">
													There are no events available for the selected date and time period.
												</div>
											)}
										</div>
									</div>
								</div>
							) : null}
						</Fragment>
					) : null;
				})}
				{selectedDates.length == 0 && this.state.selectedTime == 'all' && this.renderLoadMore(disableLoadMore)}
			</>
		);
	}

	renderLoadMore(isDisabled) {
		return (
			<button
				onClick={e => {
					!isDisabled ? this.loadMorePressed(e) : null;
				}}
				className={`load-more-button ${isDisabled ? 'disabled' : ''}`}>
				Load More
			</button>
		);
	}

	loadMorePressed(e) {
		logger.log('[TicketsModule] loadMorePressed');

		this.setState(
			{
				maxToShow: this.state.maxToShow + this.state.displayIncrement,
			},
			() => {
				logger.log('[TicketsModule] loadMorePressed - this.state:%o', this.state);
				MeasurementUtils.dispatchMeasurementCall('LoadMore', {});
			}
		);
	}

	getURLDayInfo() {
		//if specific url get the corresponding ticket info
		if (this.state?.IndividTicketsDay && this.props.tickets?.dates && !this.loadURL) {
			let urlDayStr;
			let urlDate = [];
			this.loadURL = true;

			if (this.state.IndividTicketsDay.startsWith('d')) {
				//get selected tourn day
				urlDayStr = this.state.IndividTicketsDay.replace('day', '');
				this.props.tickets.dates.find(d => {
					if (parseInt(d.tournDay) === parseInt(urlDayStr)) {
						urlDate.push(d.date);
					}
				});
			} else {
				//get fanweek info
				urlDayStr = this.state.IndividTicketsDay;
				this.props.tickets.dates.filter(f => {
					if (f.tournDay === urlDayStr) {
						urlDate.push(f.date);
					}
				});
			}

			//set url day
			this.setState({
				...this.state,
				selectedDates: urlDate,
			});

			// logger.log('[TicketsModule] getURLDayInfo - day:%o, urlDayStr:%o: urlDate:%o', this.state.IndividTicketsDay, urlDayStr, urlDate);
		}
	}

	render() {
		logger.log('[TicketsModule] render - this:%o', this);
		// logger.log(
		// 	'[TicketsModule] fanweek :%o',
		// 	get(this.props, 'tickets.dates', []).some(el => el.tournDay == 'fanweek')
		// );
		let hasFanWeek = get(this.props, 'tickets.dates', []).some(el => el.tournDay == 'fanweek');
		let hasTournament = get(this.props, 'tickets.dates', []).some(el => el.tournDay !== 'fanweek');
		const isPast = () => {
			if (this?.props?.tickets?.dates?.length > 0) {
				let lastDate = moment(
					this?.props?.tickets?.dates?.[this?.props?.tickets?.dates?.length - 1]?.date.replace(/-/g, '/')
				);
				let offset =  moment().subtract(24, 'hours');
				// logger.log('[TicketsModule] render - isPast: %o %o', lastDate?.isBefore(offset), offset.format('YYYY-MM-DD'), this?.props?.tickets?.dates?.[this?.props?.tickets?.dates?.length - 1]?.date)
				return lastDate?.isBefore(offset);
			}
		};
		return (
			<>
				<div className={`selectDatesInfo${hasFanWeek ? ' fanweek' : ''}`}>
					<div className="ticketWrapper">
						<div className="helper-wrapper">
							<div className="helper-text">When would you like to go?</div>
							<div className="helper-instruction">Select a day or multiple days</div>
						</div>
						{hasFanWeek && (
							<div className="calendar-wrapper fanweek">
								{this.genDates(this?.props?.tickets?.dates, 'fanweek')}
							</div>
						)}
						{hasTournament && (
							<div className="calendar-wrapper tournament">
								{this.genDates(this?.props?.tickets?.dates)}
							</div>
						)}

						{(hasTournament || hasFanWeek) && !isPast() && (
							<>
								<div className="selectTimeWrapper">
									<div className="helperWrapper">
										<div className="helper-text">What time of day would you like?</div>
									</div>
									{this.genSelectTime()}
								</div>
								{this.genSelectDatesInfo()}
							</>
						)}
					</div>
				</div>
			</>
		);
		// }
	}
}

TicketsModule.defaultProps = {};
